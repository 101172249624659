<template>
  <section class="hero is-black is-fullheight">
  <div class="hero-body">
    <div class="">
      <h1 class="title">
        {{prompt}}
      </h1>
      <p></p>
      <p class="subtitle" style="white-space:pre">
        {{ workout }}

      </p>
    </div>
    
  </div>
  <div class="hero-foot">
    <div class="container has-text-centered">
      <p>
        <strong><a href="mailto:mo@castrogpt.com">mo@castrogpt.com</a></strong>
      </p>
    </div>
  </div>
</section>
</template>

<script setup>
import workoutFile from "../workouts.json"
import { ref, onMounted } from 'vue'
let prompt = ref('')
let workout = ref('')
let workouts = workoutFile.workouts
let speed = 50
let i = 0
let j = 0
 onMounted(() => {
  let selection = workouts[Math.floor(Math.random() * workouts.length)]
  //display prompt letter by letter
  typeWriter()
  function typeWriter() {
    if (i < selection.prompt.length) {
      prompt.value += selection.prompt.charAt(i);
      i++;
      setTimeout(typeWriter, speed);
    }
    if(i === selection.prompt.length && j < selection.response.length){
      workout.value += selection.response.charAt(j);
      j++;
      setTimeout(typeWriter, speed);

    }
    }
})

</script>

<style>

</style>
